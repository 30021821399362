import { Injectable } from "@angular/core";
import { StorageHelper } from "../../helpers/storage.helper";
import { Subject } from "rxjs";
import {
  SignalrService,
  OperacaoSignalR,
  EnumTipoObjeto,
} from "../signalr.service";
import { LoggingService } from "../logging.service";
import { Referencia, ReferenciaCabecalho } from "src/app/models/Referencia";
import { UsuarioPreferenciasService } from "./usuario.preferencias.service";
import { ErrorHandlerService } from "../errorHandler.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AppConfig } from "src/app/app.config";

@Injectable()
export class UsuarioReferenciaService {
  readonly databaseName = "userdata";
  readonly collectionNameReferencia = "referencias";
  readonly collectionNameReferenciaCabecalho = "referencias-cabecalhos";

  private _modificado = new Subject<Referencia>();
  public $modificado = this._modificado.asObservable();

  constructor(
    private signalrService: SignalrService,
    private loggingService: LoggingService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private httpClient: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {
    signalrService.$Mensagem.subscribe((msg) => this.onMessageReceived(msg));
  }

  public carregarReferenciasPorLei(leiId: string): Promise<Referencia[]> {
    return new Promise(async (onsuccess, onerror) => {
      const url = `${AppConfig.apiEndpoint}/Referencias/Referencias`;
      let requestParams = new HttpParams();

      const referenciaslocal = await StorageHelper.list<Referencia>(
        this.databaseName,
        this.collectionNameReferencia,
        (r) => r.links?.find((e) => e.idLei === leiId)?.idLei === leiId
      );

      requestParams = requestParams.append("IdLei", leiId);
      requestParams = requestParams.append(
        "userId",
        this.usuarioPreferenciasService?.Configuracoes.id
      );

      await this.httpClient
        .get(url, { params: requestParams })
        .toPromise()
        .then((referencias: Referencia[]) => {
          if (referencias) {
            this.fromNuvem(referencias).catch((err) => {
              this.errorHandlerService.handleError(err);
              onerror(err);
            });
            onsuccess(referencias);
          }
          if (referenciaslocal) {
            onsuccess(referenciaslocal);
          } else {
            onsuccess(new Array<Referencia>());
          }
        })
        .catch((err) => {
          this.errorHandlerService.handleError(err);
          if (referenciaslocal) {
            onsuccess(referenciaslocal);
          } else {
            onsuccess(new Array<Referencia>());
          }
        });
    });
  }

  private onMessageReceived(msg: OperacaoSignalR): void {
    if (msg && msg.tipoObjeto === EnumTipoObjeto.Referencias) {
      this.atualizar(msg.dados, false);
    }
  }

  public fromNuvem(referencias: Referencia[]): Promise<void> {
    return new Promise((onsuccess, onerror) => {
      StorageHelper.upsertMany(
        referencias,
        this.databaseName,
        this.collectionNameReferencia,
        false
      )
        .then(() => {
          onsuccess();
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  public atualizar(referencia: Referencia, sync = true): Promise<Referencia> {
    return new Promise((onsuccess, onerror) => {
      if (sync) {
        const mensagem = new OperacaoSignalR();
        mensagem.dados = referencia;
        this.signalrService.enviarMensagem(
          mensagem,
          EnumTipoObjeto.Referencias
        );
      }

      StorageHelper.upsert(
        referencia,
        this.databaseName,
        this.collectionNameReferencia
      )
        .then((referencia) => {
          const novo = !referencia.dataHoraModificacao;

          this._modificado.next(referencia);
          onsuccess(referencia);

          if (novo)
            this.loggingService.LogEvent(
              "Leitor - atualizar - referencia",
              referencia.links.toString(),
              referencia.id
            );
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  public remover(referencia: Referencia, itemId: string): Promise<Referencia> {
    let index = referencia.links.findIndex((item) => item.idItem === itemId);
    if (index !== -1) {
      referencia.links.splice(index, 1);
    }
    if (referencia.links.length === 0) {
      referencia.removido = true;
    }

    return this.atualizar(referencia);
  }

  public buscar(id: string): Promise<Referencia> {
    return new Promise((onsuccess) => {
      StorageHelper.getByKey<Referencia>(
        id,
        this.databaseName,
        this.collectionNameReferencia
      )
        .then((referencia) => onsuccess(referencia))
        .catch((err) => {
          throw err;
        });
    });
  }

  public buscarLei(idLei: string): Promise<Referencia[]> {
    return new Promise((onsuccess, onerror) => {
      StorageHelper.list<Referencia>(
        this.databaseName,
        this.collectionNameReferencia,
        (m) => {
          return m.links?.find((e) => e.idLei === idLei)?.idLei === idLei;
        }
      )
        .then((referencias) => {
          onsuccess(referencias);
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  public buscarReferenciasLocal(): Promise<ReferenciaCabecalho[]> {
    return new Promise((onsuccess, onerror) => {
      StorageHelper.list<ReferenciaCabecalho>(
        this.databaseName,
        this.collectionNameReferenciaCabecalho
      )
        .then((referencias) => {
          onsuccess(referencias);
        })
        .catch((err) => {
          this.errorHandlerService.handleError(err);
          onsuccess(new Array<ReferenciaCabecalho>());
        });
    });
  }

  public buscarReferenciaLocalPorId(referenciaId: string): Promise<Referencia> {
    return new Promise((onsuccess, onerror) => {
      StorageHelper.getByKey<Referencia>(
        referenciaId,
        this.databaseName,
        this.collectionNameReferenciaCabecalho
      )
        .then((referencia) => {
          onsuccess(referencia);
        })
        .catch((err) => {
          this.errorHandlerService.handleError(err);
          onsuccess(new Referencia());
        });
    });
  }

  public carregarReferenciasCabecalho(): Promise<ReferenciaCabecalho[]> {
    return new Promise(async (onsuccess, onerror) => {
      const url = `${AppConfig.apiEndpoint}/Referencias/ReferenciasCabecalhos`;
      let requestParams = new HttpParams();

      const referenciasLocalCabecalhos =
        await StorageHelper.list<ReferenciaCabecalho>(
          this.databaseName,
          this.collectionNameReferenciaCabecalho
        );

      requestParams = requestParams.append(
        "userId",
        this.usuarioPreferenciasService?.Configuracoes.id
      );

      await this.httpClient
        .get(url, { params: requestParams })
        .toPromise()
        .then(async (referencias: ReferenciaCabecalho[]) => {
          if (referencias) {
            await StorageHelper.upsertMany<ReferenciaCabecalho>(
              referencias,
              this.databaseName,
              this.collectionNameReferenciaCabecalho
            );
            onsuccess(referencias);
          }
          if (referenciasLocalCabecalhos) {
            onsuccess(referenciasLocalCabecalhos);
          } else {
            onsuccess(new Array<ReferenciaCabecalho>());
          }
        })
        .catch((err) => {
          this.errorHandlerService.handleError(err);
          onsuccess(new Array<ReferenciaCabecalho>());
        });
    });
  }

  public carregarReferenciaPorId(referenciaId: string): Promise<Referencia> {
    return new Promise(async (onsuccess, onerror) => {
      const url = `${AppConfig.apiEndpoint}/Referencias/ReferenciaById`;
      let requestParams = new HttpParams();

      const referencia = await StorageHelper.getByKey<Referencia>(
        referenciaId,
        this.databaseName,
        this.collectionNameReferencia
      );

      requestParams = requestParams.append(
        "userId",
        this.usuarioPreferenciasService?.Configuracoes.id
      );
      requestParams = requestParams.append("referenciaId", referenciaId);

      await this.httpClient
        .get(url, { params: requestParams })
        .toPromise()
        .then(async (ref: Referencia) => {
          if (ref) {
            await StorageHelper.upsert<Referencia>(
              ref,
              this.databaseName,
              this.collectionNameReferencia
            );
            onsuccess(ref);
          }
          if (referencia) {
            onsuccess(referencia);
          } else {
            onsuccess(new Referencia());
          }
        })
        .catch((err) => {
          this.errorHandlerService.handleError(err);
          onsuccess(new Referencia());
        });
    });
  }
}

import { Injectable } from "@angular/core";
import { Grifo, MarcacaoGrifaTexto } from "../../models/Grifo";
import { StorageHelper } from "../../helpers/storage.helper";
import { Observable, BehaviorSubject } from "rxjs";
import {
  OperacaoSignalR,
  SignalrService,
  EnumTipoObjeto,
} from "../signalr.service";
import { LoggingService } from "../logging.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AppConfig } from "src/app/app.config";
import { UsuarioPreferenciasService } from "./usuario.preferencias.service";
import { ErrorHandlerService } from "../errorHandler.service";

@Injectable()
export class UsuarioGrifosService {
  readonly databaseName = "userdata";
  readonly collectionName = "grifos";

  public $modificado: Observable<Grifo>;
  private _modificado = new BehaviorSubject<Grifo>(null);

  constructor(
    private signalrService: SignalrService,
    private loggingService: LoggingService,
    private httpClient: HttpClient,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.$modificado = this._modificado.asObservable();

    signalrService.$Mensagem.subscribe((msg) => this.onMessageReceived(msg));
  }

  public carregarGrifosPorLei(leiId: string): Promise<Grifo[]> {
    return new Promise(async (onsuccess, onerror) => {
      const url = `${AppConfig.apiEndpoint}/MarcacacoesGrifaTexto/MarcacacoesGrifos`;
      let requestParams = new HttpParams();

      const grifoslocal = await StorageHelper.list<Grifo>(
        this.databaseName,
        this.collectionName,
        (g) => g.idLei === leiId
      );

      requestParams = requestParams.append("IdLei", leiId);
      requestParams = requestParams.append(
        "userId",
        this.usuarioPreferenciasService?.Configuracoes.id
      );
      await this.httpClient
        .get(url, { params: requestParams })
        .toPromise()
        .then((grifos: MarcacaoGrifaTexto) => {
          if (grifos.marcacacoesGrifaTextoItens) {
            this.fromNuvem(grifos.marcacacoesGrifaTextoItens).catch((err) => {
              this.errorHandlerService.handleError(err);
              onerror(err);
            });
            onsuccess(grifos.marcacacoesGrifaTextoItens);
          }
          if (grifoslocal) {
            onsuccess(grifoslocal);
          } else {
            onsuccess(new Array<Grifo>());
          }
        })
        .catch((err) => {
          this.errorHandlerService.handleError(err);
          if (grifoslocal) {
            onsuccess(grifoslocal);
          } else {
            onsuccess(new Array<Grifo>());
          }
        });
    });
  }

  private onMessageReceived(msg: OperacaoSignalR): void {
    if (msg && msg.tipoObjeto === EnumTipoObjeto.Grifos) {
      this.atualizar(msg.dados, false);
    }
  }

  public fromNuvem(grifos: Grifo[]): Promise<void> {
    return new Promise((onsuccess, onerror) => {
      StorageHelper.upsertMany(
        grifos,
        this.databaseName,
        this.collectionName,
        false
      )
        .then(() => {
          onsuccess();
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  public atualizar(grifo: Grifo, sync = true): Promise<Grifo> {
    return new Promise((onsuccess, onerror) => {
      const novo = !grifo.dataHoraModificacao;
      StorageHelper.upsert(grifo, this.databaseName, this.collectionName)
        .then((grifo) => {
          if (sync) {
            const mensagem = new OperacaoSignalR();
            mensagem.dados = grifo;
            this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Grifos);
          }

          this._modificado.next(grifo);
          onsuccess(grifo);

          if (novo)
            this.loggingService.LogEvent("Leitor - Marcar Texto", null, null);
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  public atualizarVarios(grifos: Grifo[], sync = true): Promise<Grifo[]> {
    return new Promise((onsuccess, onerror) => {
      const novo = !grifos[0].dataHoraModificacao;

      StorageHelper.upsertMany(grifos, this.databaseName, this.collectionName)
        .then((grifos) => {
          if (sync) {
            const mensagens = grifos.map((g) => {
              const msg = new OperacaoSignalR();
              msg.dados = g;
              return msg;
            });

            this.signalrService.enviarMensagens(
              mensagens,
              EnumTipoObjeto.Grifos
            );
          }

          // this._modificado.next(grifos.map(g => g.idItem));
          grifos.forEach((g) => {
            this._modificado.next(g);
          });
          onsuccess(grifos);

          if (novo)
            this.loggingService.LogEvent("Leitor - Marcar texto", null, null);
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  public remover(grifo: Grifo): Promise<Grifo> {
    grifo.removido = true;
    return this.atualizar(grifo);
  }

  public removerVarios(grifos: Grifo[]): Promise<Grifo[]> {
    grifos.forEach((g) => {
      g.removido = true;
    });

    return this.atualizarVarios(grifos);
  }

  public buscarLei(idLei: string): Promise<Grifo[]> {
    return new Promise((onsuccess, onerror) => {
      StorageHelper.list<Grifo>(this.databaseName, this.collectionName, (m) => {
        return m.idLei === idLei;
      })
        .then((grifos) => {
          onsuccess(grifos);
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  public buscarLinha(idLinha: string, versaoLinha: number): Promise<Grifo[]> {
    return new Promise((onsuccess, onerror) => {
      StorageHelper.list<Grifo>(this.databaseName, this.collectionName, (c) => {
        return c.idItem === idLinha && c.idImportacao === versaoLinha;
      })
        .then((marcacoes) => {
          onsuccess(marcacoes);
        })
        .catch((err) => {
          throw err;
        });
    });
  }
}
